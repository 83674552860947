import React from 'react'
import styled from '@emotion/styled'
import {graphql} from 'gatsby'
import {MDXProvider} from '@mdx-js/react'
import {MDXRenderer} from 'gatsby-plugin-mdx'
import Layout from 'components/layout'
import {getHeadings} from 'utils'
import {hidden} from 'styles'

const HiddenHeading = styled.h2`
  ${hidden};
`

export default function MdxLayout({data: {mdx, allMdx}, ...pageProps}) {
  const sectionHeadings = getHeadings(allMdx)
  const page = {
    title: mdx.frontmatter.title,
    id: mdx.frontmatter.id,
  }

  return (
    <Layout pageInfo={page} {...pageProps}>
      <main css={{position: 'relative'}}>
        <MDXProvider components={{h2: HiddenHeading}}>
          <MDXRenderer headings={sectionHeadings}>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query MdxPageQuery($id: String, $lang: String) {
    mdx(id: {eq: $id}) {
      id
      body
      fields {
        lang
      }
      frontmatter {
        id
        title
      }
    }
    allMdx(
      filter: {
        headings: {elemMatch: {depth: {eq: 2}}}
        fields: {lang: {eq: $lang}}
      }
    ) {
      nodes {
        headings(depth: h2) {
          value
        }
        frontmatter {
          order
        }
      }
    }
  }
`
